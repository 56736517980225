import Router from './Components/Router/Router'

function App() {

  return (
       <div className='App'>
      <Router/>
      </div>
  );
}

export default App;
